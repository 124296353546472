import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

export default function PageNotFound() {
  return (
    <Layout>
      <SEO title={"Top"} />
      404: Page Not Found
    </Layout>
  )
}
